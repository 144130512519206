module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LambHVAC","short_name":"LambHVAC","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"/Users/illmonk/Documents/GitHub/lambhvac-gastby/src/images/favicon.png","icons":[{"src":"/Users/illmonk/Documents/GitHub/lambhvac-gastby/src/images/favicon.png","sizes":"64x64 32x32 24x24 16x16","type":"image/png"},{"src":"/Users/illmonk/Documents/GitHub/lambhvac-gastby/src/images/lamb_logox192.png","sizes":"192x192","type":"image/png"},{"src":"/Users/illmonk/Documents/GitHub/lambhvac-gastby/src/images/lamb_logox512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a0dc5a6856df773bf36936f8d4f4cdd2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-MKEPM0GXJ9"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
